export default (function (_ref) {
  var request = _ref.request;
  return {
    userRoles: function userRoles(userId) {
      return request({
        url: "/usersRoles/".concat(userId, "/roles"),
        method: 'get'
      });
    },
    loadPatrolInspectionList: function loadPatrolInspectionList() {
      return request({
        url: '/usersRoles/list/code',
        method: 'get',
        params: {
          code: 'SE_XJY'
        }
      });
    },
    repairmanList: function repairmanList() {
      return request({
        url: '/usersRoles/list/code',
        method: 'get',
        params: {
          code: 'SE_WXY'
        }
      });
    },
    gymManagerList: function gymManagerList() {
      return request({
        url: '/usersRoles/list/code',
        method: 'get',
        params: {
          code: 'GR_MANAGER'
        }
      });
    },
    associationAdminUserList: function associationAdminUserList() {
      return request({
        url: '/usersRoles/list/code',
        method: 'get',
        params: {
          code: 'AS_ADMIN'
        }
      });
    }
  };
});