export default (function (_ref) {
  var request = _ref.request;
  return {
    aboutAdd: function aboutAdd(data) {
      return request({
        url: '/about',
        method: 'post',
        data: data
      });
    },
    aboutModify: function aboutModify(data) {
      return request({
        url: "/about/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    aboutDel: function aboutDel(id) {
      return request({
        url: "/about/".concat(id),
        method: 'delete'
      });
    },
    aboutQuery: function aboutQuery(data) {
      return request({
        url: '/about',
        method: 'get',
        params: data
      });
    },
    aboutDetail: function aboutDetail(id) {
      return request({
        url: "/about/".concat(id),
        method: 'get'
      });
    },
    aboutCache: function aboutCache(code) {
      return request({
        url: "/about/cache/".concat(code),
        method: 'get'
      });
    },
    aboutsCache: function aboutsCache(codes) {
      return request({
        url: "/about/cache?codes=".concat(codes),
        method: 'get'
      });
    },
    aboutClearCache: function aboutClearCache(code) {
      return request({
        url: "/about/cache/clear?code=".concat(code),
        method: 'post'
      });
    }
  };
});