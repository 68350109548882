import { render, staticRenderFns } from "./data_analysis_page.vue?vue&type=template&id=edabb892&scoped=true&"
import script from "./data_analysis_page.vue?vue&type=script&lang=js&"
export * from "./data_analysis_page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edabb892",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\projectSpace\\snht\\snhtweb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('edabb892')) {
      api.createRecord('edabb892', component.options)
    } else {
      api.reload('edabb892', component.options)
    }
    module.hot.accept("./data_analysis_page.vue?vue&type=template&id=edabb892&scoped=true&", function () {
      api.rerender('edabb892', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/gr/data_analysis_page.vue"
export default component.exports