import dayjs from 'dayjs';
export default (function (_ref) {
  var request = _ref.request;
  return {
    createRepairApi: function createRepairApi(data) {
      return request({
        url: '/seRepair',
        method: 'post',
        data: data
      });
    },
    repairModify: function repairModify(data) {
      return request({
        url: "/seRepair/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    repairDel: function repairDel(id) {
      return request({
        url: "/seRepair/".concat(id),
        method: 'delete'
      });
    },
    repairQuery: function repairQuery(data) {
      return request({
        url: '/seRepair',
        method: 'get',
        params: data
      });
    },
    repairDetail: function repairDetail(id) {
      return request({
        url: "/seRepair/".concat(id),
        method: 'get'
      });
    },
    repairAudit: function repairAudit(data) {
      return request({
        url: '/seRepair/audit',
        method: 'PUT',
        data: data
      });
    },
    repairReportRepairRate: function repairReportRepairRate(params) {
      return request({
        url: '/seRepair/report-repair-rate',
        method: 'GET',
        params: params
      });
    },
    repairReportRepairDuration: function repairReportRepairDuration(params) {
      return request({
        url: '/seRepair/report-repair-duration',
        method: 'GET',
        params: params
      });
    }
  };
});