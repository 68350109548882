import "core-js/modules/es.array.concat.js";
export default (function (_ref) {
  var request = _ref.request;
  return {
    payWithdrawalCreate: function payWithdrawalCreate(data) {
      return request({
        url: '/withdrawalOrder',
        method: 'post',
        data: data
      });
    },
    payWithdrawalQueryJoinUser: function payWithdrawalQueryJoinUser(data) {
      return request({
        url: '/withdrawalOrder/queryJoinUser',
        method: 'get',
        params: data
      });
    },
    payWithdrawalDetail: function payWithdrawalDetail(id) {
      return request({
        url: "/withdrawalOrder/".concat(id),
        method: 'get'
      });
    },
    payWithdrawalTransfer: function payWithdrawalTransfer(id, withdrawalTime) {
      return request({
        url: "/withdrawalOrder/".concat(id, "/transfer/").concat(withdrawalTime),
        method: 'post'
      });
    },
    payWithdrawalRejected: function payWithdrawalRejected(id, msg) {
      return request({
        url: "/withdrawalOrder/".concat(id, "/rejected?msg=").concat(msg),
        method: 'post'
      });
    }
  };
});