import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import dayjs from 'dayjs';
export default {
  name: 'pay_balance_record',
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '用户流水',
        queryFunName: 'payBalanceRecordQueryJoinUser',
        detailFunName: 'payBalanceRecordDetail'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time'
      },
      formData: {
        id: null,
        direction: null,
        content: null,
        cost: null,
        userAccount: null,
        userNickname: null
      },
      queryLoading: false,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {}
    };
  },
  created: function created() {
    this.query();
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    detail: function detail(rowData) {
      var _this = this;
      this.$api[this.pageConfig.detailFunName](rowData.id).then(function (res) {
        _this.initFormData(res.data);
        _this.formData.userAccount = rowData.userAccount;
        _this.formData.userNickname = rowData.userNickname;
        _this.editDialogTitle = _this.pageConfig.editPageName + '明细';
        _this.editDialogShow = true;
      });
    },
    query: function query() {
      var _this2 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.totalCount = res.count;
        _this2.queryLoading = false;
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};