import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import dayjs from 'dayjs';
export default {
  name: 'demo',
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '会员',
        addFunName: 'asMemberAdd',
        modifyFunName: 'asMemberModify',
        delFunName: 'asMemberDel',
        queryFunName: 'asMemberQuery',
        detailFunName: 'asMemberDetail'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time'
      },
      formData: {
        id: null,
        name: null
      },
      rules: {
        name: [{
          required: true,
          message: '标题不能为空',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      dialog: {
        authMember: {
          formRef: 'authMemberForm',
          title: '认证成员',
          show: false,
          originFormData: {},
          formData: {}
        }
      },
      formLabelWidth: '100px',
      emptyFormData: {},
      associationList: [],
      postList: []
    };
  },
  created: function created() {
    this.loadAssociationList();
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    loadPostList: function loadPostList(assId) {
      var _this = this;
      this.$api.asPostByAssociationId(assId).then(function (res) {
        if (res.data) {
          _this.postList = res.data;
        }
      });
    },
    loadAssociationList: function loadAssociationList() {
      var _this2 = this;
      this.$api.asAssociationMyAdmin().then(function (res) {
        if (res.data) {
          _this2.associationList = res.data;
        }
      });
    },
    initFormData: function initFormData(data) {
      this.formData = JSON.parse(JSON.stringify(data));
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    clearForm: function clearForm(dialogConfig) {
      var originFormData = JSON.parse(JSON.stringify(dialogConfig['originFormData']));
      this.$set(dialogConfig, 'formData', originFormData);
      var formRef = dialogConfig['formRef'];
      if (typeof this.$refs[formRef] !== 'undefined') {
        this.$refs[formRef].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      return JSON.parse(JSON.stringify(formData));
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
    },
    edit: function edit(id) {
      var _this3 = this;
      this.$api[this.pageConfig.detailFunName](id).then(function (res) {
        _this3.initFormData(res.data);
        _this3.editDialogTitle = _this3.pageConfig.editPageName + '维护';
        _this3.editDialogShow = true;
        _this3.loadPostList(res.data.associationId);
      });
    },
    del: function del(id) {
      var _this4 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this4.$api[_this4.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this4.$message.success('删除成功');
            _this4.query();
          } else {
            _this4.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this5 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this5.tableData = res.data || [];
        _this5.totalCount = res.count;
        _this5.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this6 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this6.formData.id) {
            _this6.$api[_this6.pageConfig.modifyFunName](_this6.saveFormDataProcess(_this6.formData)).then(function (res) {
              if (res.data) {
                _this6.$message.success('编辑成功');
                _this6.initFormData(_this6.emptyFormData);
                _this6.editDialogShow = false;
                _this6.query();
              } else {
                _this6.$message.error('编辑失败');
              }
            });
          } else {
            _this6.$api[_this6.pageConfig.addFunName](_this6.saveFormDataProcess(_this6.formData)).then(function (res) {
              if (res.data) {
                _this6.$message.success('新增成功');
                _this6.initFormData(_this6.emptyFormData);
                _this6.editDialogShow = false;
                _this6.query();
              } else {
                _this6.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    closeDialog: function closeDialog(dialogConfig) {
      this.$set(dialogConfig, 'show', false);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};