import _toConsumableArray from "D:/projectSpace/snht/snhtweb/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
import { assign, map } from 'lodash';
import faker from 'faker/locale/zh_CN';
import { service, request, serviceForMock, requestForMock, mock } from './service';
import * as tools from './tools';
var files = require.context('./modules', true, /\.api\.js$/);
var generators = files.keys().map(function (key) {
  return files(key).default;
});
export default assign.apply(void 0, [{}].concat(_toConsumableArray(map(generators, function (generator) {
  return generator({
    service: service,
    request: request,
    serviceForMock: serviceForMock,
    requestForMock: requestForMock,
    mock: mock,
    faker: faker,
    tools: tools
  });
}))));