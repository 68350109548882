import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
export default {
  name: 'sys_lbs',
  data: function data() {
    var _this = this;
    return {
      pageConfig: {
        editPageName: '行政区划',
        addFunName: 'lbsAdd',
        modifyFunName: 'lbsModify',
        delFunName: 'labsDel',
        queryFunName: 'lbsQuery',
        detailFunName: 'lbsDetail',
        rootId: '86'
      },
      searchForm: {
        page: 1,
        limit: 10,
        upperCode: '86'
      },
      selected: [],
      formData: {
        upperCode: '86'
      },
      rules: {
        name: [{
          required: true,
          message: '行政区划名称不能为空',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '行政区划代码不能为空',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {},
      emptySearchForm: {},
      level: 0,
      mapConfig: {
        zoom: 12,
        center: [114.06, 22.54],
        activeIndex: '',
        addressList: [],
        events: {
          // 点标记拖拽移动结束触发事件
          dragend: function dragend(e) {
            _this.mapConfig.center = [e.lnglat.lng, e.lnglat.lat];
            _this.formData.lon = e.lnglat.lng;
            _this.formData.lat = e.lnglat.lat;
          }
        }
      },
      levelList: []
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.emptySearchForm = JSON.parse(JSON.stringify(this.searchForm));
    this.query();
  },
  methods: {
    changeSelect: function changeSelect(e) {
      for (var key in e[this.level]) {
        this.formData.name = e[this.level][key];
        this.formData.code = key;
      }
      this.initMapByInput();
    },
    initMapByInput: function initMapByInput(clear) {
      var that = this;
      // 自动搜索插件
      AMap.plugin('AMap.Autocomplete', function () {
        var autoOptions = {
          city: '全国'
        };

        // 创建搜索范围
        var autoComplete = new AMap.Autocomplete(autoOptions);

        // 指定入参
        autoComplete.search(that.formData.name, function (status, e) {
          if (status === 'complete' && e.info === 'OK') {
            if (e.tips && e.tips.length > 0) {
              var tips = e.tips.filter(function (i) {
                return i.location && i.address.length > 0;
              });
              var tip = tips[0];
              if (typeof tip !== 'undefined') {
                that.mapConfig.center = [tip.location.lng, tip.location.lat];
              }
              that.mapConfig.addressList = tips;
            } else {
              that.mapConfig.addressList = [];
            }
            that.formData.lon = that.mapConfig.addressList[0].location.lng;
            that.formData.lat = that.mapConfig.addressList[0].location.lat;
            that.$forceUpdate();
            // console.log(that.mapConfig.addressList)
            // console.log(that.formData.lon)
          }
        });
      });
    },
    lowerDics: function lowerDics(upperCode, code, name) {
      this.levelList.push({
        upperCode: upperCode,
        name: name
      });
      this.searchForm = JSON.parse(JSON.stringify(this.emptySearchForm));
      this.searchForm.upperCode = code || '86';
      this.level++;
      this.query();
    },
    upperDics: function upperDics() {
      this.level--;
      this.searchForm.upperCode = this.levelList.pop().upperCode;
      this.query();
    },
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
      this.formData.upperCode = this.searchForm.upperCode;
    },
    edit: function edit(id) {
      var _this2 = this;
      this.$api[this.pageConfig.detailFunName](id).then(function (res) {
        _this2.initFormData(res.data);
        _this2.editDialogTitle = _this2.pageConfig.editPageName + '维护';
        _this2.editDialogShow = true;
      });
    },
    del: function del(id) {
      var _this3 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this3.$api[_this3.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this3.$message.success('删除成功');
            _this3.query();
          } else {
            _this3.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this4 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.totalCount = res.count;
        _this4.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this5 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this5.formData.id) {
            _this5.$api[_this5.pageConfig.modifyFunName](_this5.saveFormDataProcess(_this5.formData)).then(function (res) {
              if (res.data) {
                _this5.$message.success('编辑成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('编辑失败');
              }
            });
          } else {
            _this5.$api[_this5.pageConfig.addFunName](_this5.saveFormDataProcess(_this5.formData)).then(function (res) {
              if (res.data) {
                _this5.$message.success('新增成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : '^sort';
      this.query();
    }
  }
};