var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("el-date-picker", {
    attrs: {
      type: "datetimerange",
      "picker-options": _vm.pickerOptions,
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      align: "right"
    },
    on: {
      change: _vm.changeDatePicker
    },
    model: {
      value: _vm.datePickerValue,
      callback: function callback($$v) {
        _vm.datePickerValue = $$v;
      },
      expression: "datePickerValue"
    }
  }), _c("div", {
    staticStyle: {
      width: "400px",
      height: "300px"
    },
    attrs: {
      id: "repairRate"
    }
  }), _c("div", {
    staticStyle: {
      width: "400px",
      height: "300px"
    },
    attrs: {
      id: "repairDuration"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };