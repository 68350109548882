import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import dayjs from 'dayjs';
export default {
  name: 'demo',
  props: {
    current: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '器材',
        addFunName: 'seAdd',
        modifyFunName: 'seModify',
        delFunName: 'seDel',
        queryFunName: 'seQuery',
        detailFunName: 'seDetail'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        pathId: '',
        sortField: 'create_time'
      },
      formData: {
        id: null
      },
      rules: {
        equipmentName: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        equipmentTypeValue: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        installDate: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        manufacturer: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        manufacturerPhone: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        streetOffice: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        community: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {},
      manufacturerConfig: [{
        name: '英派斯',
        phone: '85793197'
      }, {
        name: '舒华',
        phone: '83810611'
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // const query = this.$route.query;
    console.log('query', this.current);
    this.$set(this.searchForm, 'pathId', this.current.id);
    this.$set(this.formData, 'pathId', this.current.id);
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    changeEquipmentTypeValue: function changeEquipmentTypeValue(option) {
      console.log('changeEquipmentTypeValue', option);
      var arr = this.dictionaryGroup('SE_TYPE');
      var filter = arr.filter(function (i) {
        return i.code === option;
      });
      if (filter.length > 0) {
        this.$set(this.formData, 'equipmentName', filter[0].name);
      }
    },
    changeManufacturer: function changeManufacturer(option) {
      var filter = this.manufacturerConfig.filter(function (i) {
        return i.name === option;
      });
      if (filter.length > 0) {
        this.$set(this.formData, 'manufacturerPhone', filter[0].phone);
      }
    },
    back: function back() {
      this.$emit('child-go-outer');
    },
    initFormData: function initFormData(data) {
      this.formData = JSON.parse(JSON.stringify(data));
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      return JSON.parse(JSON.stringify(formData));
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
    },
    edit: function edit(id) {
      var _this = this;
      this.$api[this.pageConfig.detailFunName](id).then(function (res) {
        _this.initFormData(res.data);
        _this.editDialogTitle = _this.pageConfig.editPageName + '维护';
        _this.editDialogShow = true;
      });
    },
    del: function del(id) {
      var _this2 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this2.$api[_this2.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this2.$message.success('删除成功');
            _this2.query();
          } else {
            _this2.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.totalCount = res.count;
        _this3.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this4 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this4.formData.id) {
            _this4.$api[_this4.pageConfig.modifyFunName](_this4.saveFormDataProcess(_this4.formData)).then(function (res) {
              if (res.data) {
                _this4.$message.success('编辑成功');
                _this4.initFormData(_this4.emptyFormData);
                _this4.editDialogShow = false;
                _this4.query();
              } else {
                _this4.$message.error('编辑失败');
              }
            });
          } else {
            _this4.$api[_this4.pageConfig.addFunName](_this4.saveFormDataProcess(_this4.formData)).then(function (res) {
              if (res.data) {
                _this4.$message.success('新增成功');
                _this4.initFormData(_this4.emptyFormData);
                _this4.editDialogShow = false;
                _this4.query();
              } else {
                _this4.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};