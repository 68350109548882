import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
export default {
  name: 'pm_project_server',
  props: {
    currentProject: {
      type: Object
    }
  },
  data: function data() {
    return {
      pageConfig: {
        editPageName: '项目服务器',
        addFunName: 'projectServerAdd',
        modifyFunName: 'projectServerModify',
        delFunName: 'projectServerDel',
        queryFunName: 'projectServerQuery',
        detailFunName: 'projectServerDetail'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time',
        projectId: this.currentProject.id
      },
      formData: {
        id: null,
        projectId: this.currentProject.id,
        name: null,
        providers: null,
        wanIp: null,
        lanIp: null,
        account: null,
        password: null,
        configuration: null,
        osVersion: null,
        buyDate: null,
        expirationDate: null,
        remark: null
      },
      rules: {
        name: [{
          required: true,
          message: '名称不能为空',
          trigger: 'blur'
        }],
        providers: [{
          required: true,
          message: '供应商不能为空',
          trigger: 'blur'
        }],
        wanIp: [{
          required: true,
          message: '广域网IP不能为空',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {}
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    back: function back() {
      this.$emit('child-go-project');
    },
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      if (paramData.buyDate) {
        paramData.buyDate = paramData.buyDate.toString();
      }
      if (paramData.expirationDate) {
        paramData.expirationDate = paramData.expirationDate.toString();
      }
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
    },
    edit: function edit(id) {
      var _this = this;
      this.$api[this.pageConfig.detailFunName](id).then(function (res) {
        _this.initFormData(res.data);
        _this.editDialogTitle = _this.pageConfig.editPageName + '维护';
        _this.editDialogShow = true;
      });
    },
    del: function del(id) {
      var _this2 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this2.$api[_this2.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this2.$message.success('删除成功');
            _this2.query();
          } else {
            _this2.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.totalCount = res.count;
        _this3.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this4 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this4.formData.id) {
            _this4.$api[_this4.pageConfig.modifyFunName](_this4.saveFormDataProcess(_this4.formData)).then(function (res) {
              if (res.data) {
                _this4.$message.success('编辑成功');
                _this4.initFormData(_this4.emptyFormData);
                _this4.editDialogShow = false;
                _this4.query();
              } else {
                _this4.$message.error('编辑失败');
              }
            });
          } else {
            _this4.$api[_this4.pageConfig.addFunName](_this4.saveFormDataProcess(_this4.formData)).then(function (res) {
              if (res.data) {
                _this4.$message.success('新增成功');
                _this4.initFormData(_this4.emptyFormData);
                _this4.editDialogShow = false;
                _this4.query();
              } else {
                _this4.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};