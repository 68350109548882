export default (function (_ref) {
  var request = _ref.request;
  return {
    projectAccountAdd: function projectAccountAdd(data) {
      return request({
        url: '/projectAccount',
        method: 'post',
        data: data
      });
    },
    projectAccountModify: function projectAccountModify(data) {
      return request({
        url: "/projectAccount/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    projectAccountDel: function projectAccountDel(id) {
      return request({
        url: "/projectAccount/".concat(id),
        method: 'delete'
      });
    },
    projectAccountQuery: function projectAccountQuery(data) {
      return request({
        url: '/projectAccount',
        method: 'get',
        params: data
      });
    },
    projectAccountQueryJoinProject: function projectAccountQueryJoinProject(data) {
      return request({
        url: '/projectAccount/queryJoinProject',
        method: 'get',
        params: data
      });
    },
    projectAccountDetail: function projectAccountDetail(id) {
      return request({
        url: "/projectAccount/".concat(id),
        method: 'get'
      });
    }
  };
});