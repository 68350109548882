import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'se_path_mng',
  components: {
    pathTable: function pathTable() {
      return import('./se_path_page');
    },
    innerPathTable: function innerPathTable() {
      return import('./se_path_inner_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'outer',
      current: {}
    };
  },
  methods: {
    goOuter: function goOuter() {
      this.tableShow = 'outer';
    },
    goChild: function goChild(data, tableName) {
      this.current = data;
      this.tableShow = tableName;
    }
  }
};