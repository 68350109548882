export default (function (_ref) {
  var request = _ref.request;
  return {
    configAdd: function configAdd(data) {
      return request({
        url: '/config',
        method: 'post',
        data: data
      });
    },
    configModify: function configModify(data) {
      return request({
        url: "/config/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    configDel: function configDel(id) {
      return request({
        url: "/config/".concat(id),
        method: 'delete'
      });
    },
    configQuery: function configQuery(data) {
      return request({
        url: '/config',
        method: 'get',
        params: data
      });
    },
    configDetail: function configDetail(id) {
      return request({
        url: "/config/".concat(id),
        method: 'get'
      });
    },
    configCache: function configCache(code) {
      return request({
        url: "/config/cache/".concat(code),
        method: 'get'
      });
    },
    configsCache: function configsCache(codes) {
      return request({
        url: "/config/cache?codes=".concat(codes),
        method: 'get'
      });
    },
    configClearCache: function configClearCache(code) {
      return request({
        url: "/config/cache/clear?code=".concat(code || ''),
        method: 'delete'
      });
    }
  };
});