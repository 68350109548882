import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import dayjs from 'dayjs';
export default (function (_ref) {
  var request = _ref.request;
  return {
    gmApplyAdd: function gmApplyAdd(data) {
      return request({
        url: '/gmApply',
        method: 'post',
        data: data
      });
    },
    gmApplyModify: function gmApplyModify(data) {
      return request({
        url: "/gmApply/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    gmApplyDel: function gmApplyDel(id) {
      return request({
        url: "/gmApply/".concat(id),
        method: 'delete'
      });
    },
    gmApplyQuery: function gmApplyQuery(data) {
      return request({
        url: '/gmApply',
        method: 'get',
        params: data
      });
    },
    gmApplyDetail: function gmApplyDetail(id) {
      return request({
        url: "/gmApply/".concat(id),
        method: 'get'
      });
    },
    auditGameApply: function auditGameApply(params) {
      return request({
        url: '/gmApply/audit',
        method: 'get',
        params: params
      });
    },
    gmApplyExportExcel: function gmApplyExportExcel(params) {
      return request({
        url: '/gmApply/export-excel',
        method: 'get',
        params: params,
        headers: {
          'Content-Type': 'multipart/form-data; boundary = ' + new Date().getTime(),
          Authorization: localStorage.getItem('token')
        },
        responseType: 'blob'
      }).then(function (res) {
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(res);
        downloadElement.href = href;
        var format = dayjs().format('YYYYMMDDHHmmss');
        downloadElement.download = "\u8D5B\u4E8B\u62A5\u540D\u8868_".concat(format, ".xls");
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    }
  };
});