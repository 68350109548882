export default (function (_ref) {
  var request = _ref.request;
  return {
    grGymAdd: function grGymAdd(data) {
      return request({
        url: '/grGym',
        method: 'post',
        data: data
      });
    },
    grGymModify: function grGymModify(data) {
      return request({
        url: "/grGym/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    grGymDel: function grGymDel(id) {
      return request({
        url: "/grGym/".concat(id),
        method: 'delete'
      });
    },
    grGymQuery: function grGymQuery(data) {
      return request({
        url: '/grGym',
        method: 'get',
        params: data
      });
    },
    grGymDetail: function grGymDetail(id) {
      return request({
        url: "/grGym/".concat(id),
        method: 'get'
      });
    },
    myGymList: function myGymList() {
      return request({
        url: '/grGym/my-gym',
        method: 'get'
      });
    }
  };
});