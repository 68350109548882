import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'pm_project_mng',
  components: {
    projectTable: function projectTable() {
      return import('./project_page');
    },
    accountTable: function accountTable() {
      return import('./project_account_page');
    },
    serverTable: function serverTable() {
      return import('./project_server_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'project',
      currentProject: null
    };
  },
  methods: {
    goProject: function goProject() {
      this.tableShow = 'project';
    },
    goChild: function goChild(data, tableName) {
      this.currentProject = data;
      this.tableShow = tableName;
    }
  }
};