export default (function (_ref) {
  var request = _ref.request;
  return {
    asMemberAdd: function asMemberAdd(data) {
      return request({
        url: '/asMember',
        method: 'post',
        data: data
      });
    },
    asMemberModify: function asMemberModify(data) {
      return request({
        url: "/asMember/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    asMemberDel: function asMemberDel(id) {
      return request({
        url: "/asMember/".concat(id),
        method: 'delete'
      });
    },
    asMemberQuery: function asMemberQuery(data) {
      return request({
        url: '/asMember',
        method: 'get',
        params: data
      });
    },
    asMemberDetail: function asMemberDetail(id) {
      return request({
        url: "/asMember/".concat(id),
        method: 'get'
      });
    }
  };
});