export default (function (_ref) {
  var request = _ref.request;
  return {
    gmMomentsAdd: function gmMomentsAdd(data) {
      return request({
        url: '/gmMoments',
        method: 'post',
        data: data
      });
    },
    gmMomentsModify: function gmMomentsModify(data) {
      return request({
        url: "/gmMoments/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    gmMomentsDel: function gmMomentsDel(id) {
      return request({
        url: "/gmMoments/".concat(id),
        method: 'delete'
      });
    },
    gmMomentsQuery: function gmMomentsQuery(data) {
      return request({
        url: '/gmMoments',
        method: 'get',
        params: data
      });
    },
    gmMomentsDetail: function gmMomentsDetail(id) {
      return request({
        url: "/gmMoments/".concat(id),
        method: 'get'
      });
    }
  };
});