export default (function (_ref) {
  var request = _ref.request;
  return {
    projectAdd: function projectAdd(data) {
      return request({
        url: '/project',
        method: 'post',
        data: data
      });
    },
    projectModify: function projectModify(data) {
      return request({
        url: "/project/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    projectDel: function projectDel(id) {
      return request({
        url: "/project/".concat(id),
        method: 'delete'
      });
    },
    projectQuery: function projectQuery(data) {
      return request({
        url: '/project',
        method: 'get',
        params: data
      });
    },
    projectQueryJoinBugStats: function projectQueryJoinBugStats(data) {
      return request({
        url: '/project/queryJoinBugStats',
        method: 'get',
        params: data
      });
    },
    projectDetail: function projectDetail(id) {
      return request({
        url: "/project/".concat(id),
        method: 'get'
      });
    }
  };
});