import "core-js/modules/es.array.concat.js";
import layoutHeaderAside from '@/layout/header-aside';

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

/**
 * 在主框架内显示
 */
var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [
  // ///////// //
  //    首页   //
  // ///////// //
  {
    path: 'index',
    name: 'index',
    meta: {
      auth: true
    },
    component: _import('system/index')
  },
  // region 器材管理
  {
    path: '/se_path',
    name: 'se_path',
    meta: {
      title: '健身路径',
      auth: true
    },
    component: _import('se/se_path_mng')
  },
  // { path: '/se_path_inner', name: 'se_path_inner', meta: { title: '健身路径内页', auth: true }, component: _import('se/se_path_inner_page') },
  {
    path: '/sports/equipment',
    name: '/sports/equipment',
    meta: {
      title: '器材',
      auth: true
    },
    component: _import('se/sports_equipment_page')
  }, {
    path: '/citizen_patrol',
    name: '/citizen_patrol',
    meta: {
      title: '市民报修',
      auth: true
    },
    component: _import('se/citizen_repair_page')
  }, {
    path: '/patrol_inspection',
    name: '/patrol_inspection',
    meta: {
      title: '巡检单',
      auth: true
    },
    component: _import('se/patrol_inspection_page')
  }, {
    path: '/repair',
    name: '/repair',
    meta: {
      title: '维修单',
      auth: true
    },
    component: _import('se/repair_page')
  }, {
    path: '/statistic_analysis',
    name: '/statistic_analysis',
    meta: {
      title: '统计分析',
      auth: true
    },
    component: _import('se/statistic_analysis_page')
  }, {
    path: '/se_video_tutorial',
    name: 'se_video_tutorial',
    meta: {
      title: '视频教程',
      auth: true
    },
    component: _import('se/se_video_tutorial_page')
  },
  // endregion
  // region 运动场所管理
  {
    path: '/gr/gym',
    name: '/gym',
    meta: {
      title: '运动场所',
      auth: true
    },
    component: _import('gr/gym_page')
  }, {
    path: '/gr/reservation_config',
    name: '/reservation_config',
    meta: {
      title: '预约配置',
      auth: true
    },
    component: _import('gr/reservation_config_page')
  }, {
    path: '/gr/data_analysis',
    name: '/data_analysis',
    meta: {
      title: '数据分析',
      auth: true
    },
    component: _import('gr/data_analysis_page')
  },
  // endregion
  // region 社会组织
  {
    path: '/as/association',
    name: '/association',
    meta: {
      title: '社会组织',
      auth: true
    },
    component: _import('as/association_page')
  }, {
    path: '/as/post',
    name: '/post',
    meta: {
      title: '社会组织架构',
      auth: true
    },
    component: _import('as/post_page')
  }, {
    path: '/as/member',
    name: '/member',
    meta: {
      title: '社会组织会员',
      auth: true
    },
    component: _import('as/member_page')
  }, {
    path: '/as/notice',
    name: '/notice',
    meta: {
      title: '社会组织公告',
      auth: true
    },
    component: _import('as/notice_page')
  }, {
    path: '/as/activity',
    name: '/activity',
    meta: {
      title: '社会组织活动',
      auth: true
    },
    component: _import('as/activity_page')
  },
  // endregion
  // region 赛事
  {
    path: '/gm/game',
    name: '/game',
    meta: {
      title: '赛事管理',
      auth: true
    },
    component: _import('gm/game_page')
  }, {
    path: '/gm/apply',
    name: '/apply',
    meta: {
      title: '赛事报名',
      auth: true
    },
    component: _import('gm/apply_page')
  }, {
    path: '/gm/moments',
    name: '/moments',
    meta: {
      title: '精彩时刻',
      auth: true
    },
    component: _import('gm/moments_page')
  }, {
    path: '/gm/game_result',
    name: '/game_result',
    meta: {
      title: '赛事成绩',
      auth: true
    },
    component: _import('gm/game_result_page')
  },
  // endregion
  // region 支付管理
  {
    path: '/pay_pay_order',
    name: '/pay_order',
    meta: {
      title: '支付订单',
      auth: true
    },
    component: _import('pay/pay_order_page')
  }, {
    path: '/pay_refund_order',
    name: '/refund_order',
    meta: {
      title: '退款订单',
      auth: true
    },
    component: _import('pay/refund_order_page')
  }, {
    path: '/pay_withdrawal_order',
    name: '/withdrawal_order',
    meta: {
      title: '提现订单',
      auth: true
    },
    component: _import('pay/withdrawal_order_page')
  }, {
    path: '/pay_balance_record',
    name: '/balance_record',
    meta: {
      title: '余额流水',
      auth: true
    },
    component: _import('pay/balance_record_page')
  },
  // endregion
  // region 资金管理
  {
    path: '/fund/my_account',
    name: '/my_account',
    meta: {
      title: '我的账户',
      auth: true
    },
    component: _import('fund/my_account_page')
  },
  // endregion
  // ///////// //
  // 用户权限 //
  // //////// //
  {
    path: 'user_menu',
    name: 'user_menu',
    meta: {
      title: '菜单管理',
      auth: true
    },
    component: _import('user/menu_page')
  }, {
    path: 'user_role',
    name: 'user_role',
    meta: {
      title: '角色管理',
      auth: true
    },
    component: _import('user/role_page')
  }, {
    path: 'mng_admin',
    name: 'mng_admin',
    meta: {
      title: '管理员管理',
      auth: true
    },
    component: _import('user/admin_page')
  }, {
    path: 'business_admin',
    name: 'business_admin',
    meta: {
      title: '业务人员',
      auth: true
    },
    component: _import('user/jdbsc_admin_page')
  },
  // ///////// //
  // 系统管理 //
  // //////// //
  {
    path: 'sys_dictionary',
    name: 'sys_dictionary',
    meta: {
      title: '字典管理',
      auth: true
    },
    component: _import('sys/dictionary_page')
  }, {
    path: 'sys_config',
    name: 'sys_config',
    meta: {
      title: '配置管理',
      auth: true
    },
    component: _import('sys/config_page')
  }, {
    path: 'sys_switch',
    name: 'sys_switch',
    meta: {
      title: '开关管理',
      auth: true
    },
    component: _import('sys/switch_page')
  }, {
    path: 'sys_about',
    name: 'sys_about',
    meta: {
      title: '关于系统',
      auth: true
    },
    component: _import('sys/about_page')
  }, {
    path: 'sys_file',
    name: 'sys_file',
    meta: {
      title: '文件管理',
      auth: true
    },
    component: _import('sys/file_page')
  }, {
    path: 'sys_lbs',
    name: 'sys_lbs',
    meta: {
      title: '行政区划',
      auth: true
    },
    component: _import('sys/lbs_page')
  },
  // ///////// //
  // 内容管理 //
  // //////// //
  {
    path: 'cms_article_simple',
    name: 'cms_article_simple',
    meta: {
      title: '文章管理',
      auth: true
    },
    component: _import('cms/article_simple_page')
  }, {
    path: 'cms_notice',
    name: 'cms_notice',
    meta: {
      title: '公告管理',
      auth: true
    },
    component: _import('cms/notice_page')
  }, {
    path: 'cms_advertising',
    name: 'cms_advertising',
    meta: {
      title: '广告管理',
      auth: true
    },
    component: _import('cms/advertising_page')
  }, {
    path: 'cms_carousel',
    name: 'cms_carousel',
    meta: {
      title: '轮播管理',
      auth: true
    },
    component: _import('cms/carousel_page')
  }, {
    path: 'cms_feedback',
    name: 'cms_feedback',
    meta: {
      title: '反馈管理',
      auth: true
    },
    component: _import('cms/feedback_page')
  }, {
    path: 'cms_work_order',
    name: 'cms_work_order',
    meta: {
      title: '工单管理',
      auth: true
    },
    component: _import('cms/work_order_page')
  },
  // /////// //
  // 官网管理 //
  // ////// //
  {
    path: 'website_navigation_article',
    name: 'website_navigation_article',
    meta: {
      title: '导航文章',
      auth: true
    },
    component: _import('website/navigation_article/navigation_article_mng_page')
  }, {
    path: 'website_outlink',
    name: 'website_outlink',
    meta: {
      title: '外链管理',
      auth: true
    },
    component: _import('website/outlink_page')
  },
  // ///////// //
  // 记录管理 //
  // //////// //
  {
    path: 'app_version',
    name: 'app_version',
    meta: {
      title: 'APP版本',
      auth: true
    },
    component: _import('record/app_version_page')
  }, {
    path: 'record_log',
    name: 'record_log',
    meta: {
      title: '日志管理',
      auth: true
    },
    component: _import('record/log_page')
  }, {
    path: 'db_bak',
    name: 'db_bak',
    meta: {
      title: '数据库备份',
      auth: true
    },
    component: _import('record/db_bak_page')
  },
  // /////// //
  // 项目管理 //
  // ////// //
  {
    path: 'pm_project_mng',
    name: 'pm_project_mng',
    meta: {
      title: '项目管理',
      auth: true
    },
    component: _import('pm/project/project_mng_page')
  },
  // /////// //
  // 其他功能 //
  // ////// //
  {
    // 系统 前端日志
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: _import('system/log')
  }, {
    // 刷新页面 必须保留
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  }, {
    // 页面重定向 必须保留
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  }]
}];

/**
 * 在主框架之外显示
 */
var frameOut = [
// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}, {
  path: '/login_code',
  name: 'login_code',
  component: _import('system/login/login_code')
}];

/**
 * 错误页面
 */
var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}, {
  path: '*',
  name: '411',
  component: _import('system/error/411')
}];

// 导出需要显示菜单的
export var frameInRoutes = frameIn;

// 重新组织后导出
export default [].concat(frameIn, frameOut, errorPage);