import _objectSpread from "D:/projectSpace/snht/snhtweb/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import d2MenuSide from './components/menu-side';
import d2MenuHeader from './components/menu-header';
import d2Tabs from './components/tabs';
import d2HeaderFullscreen from './components/header-fullscreen';
import d2HeaderSize from './components/header-size';
import d2HeaderTheme from './components/header-theme';
import d2HeaderUser from './components/header-user';
import d2HeaderColor from './components/header-color';
import { mapState, mapGetters, mapActions } from 'vuex';
import util from '@/libs/util';
export default {
  name: 'd2-layout-header-aside',
  components: {
    d2MenuSide: d2MenuSide,
    d2MenuHeader: d2MenuHeader,
    d2Tabs: d2Tabs,
    d2HeaderFullscreen: d2HeaderFullscreen,
    d2HeaderSize: d2HeaderSize,
    d2HeaderTheme: d2HeaderTheme,
    d2HeaderUser: d2HeaderUser,
    d2HeaderColor: d2HeaderColor
  },
  data: function data() {
    return {
      // [侧边栏宽度] 正常状态
      asideWidth: '200px'
      // [侧边栏宽度] 折叠状态
      ,
      asideWidthCollapse: '65px',
      sysIndexLeftLogo: '',
      sysIndexLeftFoldLogo: '',
      halo6AdminUrl: '',
      ShowRemind: false,
      noticeCount: 0,
      show: false,
      currentUser: localStorage.getItem('userId')
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('d2admin', {
    keepAlive: function keepAlive(state) {
      return state.page.keepAlive;
    },
    grayActive: function grayActive(state) {
      return state.gray.active;
    },
    transitionActive: function transitionActive(state) {
      return state.transition.active;
    },
    asideCollapse: function asideCollapse(state) {
      return state.menu.asideCollapse;
    },
    asideTransition: function asideTransition(state) {
      return state.menu.asideTransition;
    }
  })), mapGetters('d2admin', {
    themeActiveSetting: 'theme/activeSetting'
  })), {}, {
    routerViewKey: function routerViewKey() {
      // 默认情况下 key 类似 __transition-n-/foo
      // 这里的字符串操作是为了最终 key 的格式和原来相同 类似 __transition-n-__stamp-time-/foo
      var stamp = this.$route.meta["__stamp-".concat(this.$route.path)] || '';
      return "".concat(stamp ? "__stamp-".concat(stamp, "-") : '').concat(this.$route.path);
    }
    /**
     * @description 最外层容器的背景图片样式
     */
    ,
    styleLayoutMainGroup: function styleLayoutMainGroup() {
      return this.themeActiveSetting.backgroundImage ? {
        backgroundImage: "url('".concat(this.$baseUrl).concat(this.themeActiveSetting.backgroundImage, "')")
      } : {};
    }
  }),
  created: function created() {
    this.getSwitch();
    this.getLogo();
    this.startRefreshToken();
    // this.getLbs()

    // 设置侧边栏菜单
    var menu = JSON.parse(localStorage.getItem('menu'));
    menu = util.changeMenu(menu);
    this.$store.commit('d2admin/menu/asideSet', []);
    this.$store.commit('d2admin/menu/headerSet', menu);
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions('d2admin/menu', ['asideCollapseToggle'])), mapActions('d2admin/account', ['refreshToken'])), {}, {
    handleToggleAside: function handleToggleAside() {
      this.asideCollapseToggle();
    }
    /**
     * 定时查询token是否濒临过期，刷新token
     */
    ,
    startRefreshToken: function startRefreshToken() {
      var _this = this;
      var refreshTimer = setInterval(function () {
        _this.refreshToken();
      }, 3000);
      localStorage.setItem('refreshTimer', refreshTimer);
    } // 获取logo
    ,
    getLogo: function getLogo() {
      var _this2 = this;
      this.$api['aboutsCache']('sys_index_left_logo,sys_index_left_fold_logo').then(function (res) {
        if (res.data) {
          _this2.sysIndexLeftLogo = res.data.sys_index_left_logo;
          _this2.sysIndexLeftFoldLogo = res.data.sys_index_left_fold_logo;
        } else {
          _this2.$message.error(res.msg);
        }
      });
      this.$api['configsCache']('Halov_Admin_URL').then(function (res) {
        if (res.data) {
          _this2.halo6AdminUrl = res.data.Halo6_Admin_URL;
        } else {
          _this2.$message.error(res.msg);
        }
      });
    },
    getSwitch: function getSwitch() {
      var _this3 = this;
      this.$api['switchCache']('Show_Remind').then(function (res) {
        if (res.data) {
          _this3.ShowRemind = res.data;
          if (res.data) {
            // this.getUnNotice()
            var refreshTime = process.env.VUE_APP_REFRESHTIME;
            _this3.timer = setInterval(function () {
              // this.getUnNotice()
            }, refreshTime * 1000);
          }
        }
      });
    } // , getUnNotice() {
    //   // console.log('getUnNotice')
    //   this.$api['mngUnNoticeCount']().then((res) => {
    //     this.noticeCount = res.data || 0
    //     if (res.data) {
    //       this.count = 0
    //       this.$nextTick(() => {
    //         this.audioTimer = setInterval(() => {
    //           // console.log('音频播放',this.$refs.initAudio)
    //           this.count++
    //           this.$refs.initAudio.play()
    //           if (this.count >= this.noticeCount) {
    //             // console.log('清除播放音频定时器')
    //             clearInterval(this.audioTimer)
    //           }
    //         }, 1500)
    //       })
    //     }
    //   })
    // }
    // // 获取行政区划
    // , getLbs(){
    //   this.$api["mngLbsJson"]().then((res) => {
    //     localStorage.setItem("lbsJson",JSON.stringify(res))
    //   })
    // }
    ,
    outlinkClick: function outlinkClick() {
      window.open(this.halo6AdminUrl, '_blank');
    }
  })
};