export default (function (_ref) {
  var request = _ref.request;
  return {
    capacityDailyReservationRate: function capacityDailyReservationRate(params) {
      return request({
        url: '/grReservationCapacity/chart/daily-reservation-rate',
        method: 'get',
        params: params
      });
    }
  };
});