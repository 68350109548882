// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.woff2?t=1646192561783");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./iconfont.woff?t=1646192561783");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./iconfont.ttf?t=1646192561783");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"iconcompany\"; /* Project id 3208247 */\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n.iconcompany {\n  font-family: \"iconcompany\" !important;\n  font-size: 16px;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n.icon-company-bumen:before {\n  content: \"\\e605\";\n}\n.icon-company-huaban:before {\n  content: \"\\e625\";\n}\n.icon-company-zuzhi:before {\n  content: \"\\e669\";\n}\n.icon-company-jigou:before {\n  content: \"\\e62c\";\n}\n.icon-company-jigou1:before {\n  content: \"\\e61a\";\n}\n.icon-company-zhifubao:before {\n  content: \"\\e694\";\n}\n.icon-company-qiyeweixin:before {\n  content: \"\\e6af\";\n}\n.icon-company-pingbandiannao:before {\n  content: \"\\e600\";\n}\n.icon-company-weixin:before {\n  content: \"\\e601\";\n}\n.icon-company-shouji:before {\n  content: \"\\e692\";\n}\n.icon-company-xiaomi:before {\n  content: \"\\e661\";\n}\n.icon-company-qq:before {\n  content: \"\\e60e\";\n}\n.icon-company-changyonglogo45:before {\n  content: \"\\e727\";\n}\n.icon-company-weibo:before {\n  content: \"\\e63e\";\n}\n.icon-company-luyouqi:before {\n  content: \"\\ec62\";\n}\n.icon-company-diannao:before {\n  content: \"\\e67b\";\n}\n.icon-company-WIFI:before {\n  content: \"\\e60a\";\n}\n.icon-company-WIFI1:before {\n  content: \"\\e60c\";\n}\n.icon-company-android:before {\n  content: \"\\e8c8\";\n}\n.icon-company-aliyun1:before {\n  content: \"\\e66e\";\n}\n.icon-company-BaiDu:before {\n  content: \"\\e602\";\n}\n.icon-company-Huawei:before {\n  content: \"\\e621\";\n}\n.icon-company-ios:before {\n  content: \"\\e626\";\n}\n\n", ""]);
// Exports
module.exports = exports;
