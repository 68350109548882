import D2PageCover from './components/d2-page-cover';
export default {
  components: {
    D2PageCover: D2PageCover
  },
  data: function data() {
    return {
      sys_index_logo: ''
    };
  },
  mounted: function mounted() {
    this.getLogo();
  },
  methods: {
    // TODO: 首页Logo
    getLogo: function getLogo() {
      var _this = this;
      this.$api['aboutsCache']('sys_index_logo').then(function (res) {
        if (res.data) {
          _this.sys_index_logo = res.data.sys_index_logo || '';
        }
      });
    }
  }
};