export default (function (_ref) {
  var request = _ref.request;
  return {
    asActivityAdd: function asActivityAdd(data) {
      return request({
        url: '/asActivity',
        method: 'post',
        data: data
      });
    },
    asActivityModify: function asActivityModify(data) {
      return request({
        url: "/asActivity/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    asActivityDel: function asActivityDel(id) {
      return request({
        url: "/asActivity/".concat(id),
        method: 'delete'
      });
    },
    asActivityQuery: function asActivityQuery(data) {
      return request({
        url: '/asActivity',
        method: 'get',
        params: data
      });
    },
    asActivityDetail: function asActivityDetail(id) {
      return request({
        url: "/asActivity/".concat(id),
        method: 'get'
      });
    }
  };
});