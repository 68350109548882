export default (function (_ref) {
  var request = _ref.request;
  return {
    asAssociationAdd: function asAssociationAdd(data) {
      return request({
        url: '/asAssociation',
        method: 'post',
        data: data
      });
    },
    asAssociationModify: function asAssociationModify(data) {
      return request({
        url: "/asAssociation/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    asAssociationDel: function asAssociationDel(id) {
      return request({
        url: "/asAssociation/".concat(id),
        method: 'delete'
      });
    },
    asAssociationQuery: function asAssociationQuery(data) {
      return request({
        url: '/asAssociation',
        method: 'get',
        params: data
      });
    },
    asAssociationDetail: function asAssociationDetail(id) {
      return request({
        url: "/asAssociation/".concat(id),
        method: 'get'
      });
    },
    asAssociationMyAdmin: function asAssociationMyAdmin() {
      return request({
        url: '/asAssociation/my-admin',
        method: 'get'
      });
    }
  };
});