export default (function (_ref) {
  var request = _ref.request;
  return {
    ticketWeekDistribution: function ticketWeekDistribution(params) {
      return request({
        url: '/grTicket/chart/week-distribution',
        method: 'get',
        params: params
      });
    }
  };
});