import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import dayjs from 'dayjs';
export default {
  name: 'cms_work_order',
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '工单',
        queryFunName: 'workOrderQuery',
        activeWorkOrderFunName: 'workOrderRead',
        queryReplayFunName: 'workOrderReplayQuery',
        addReplayFunName: 'workOrderReplayAdd'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        readState: null,
        replyState: null,
        sortField: 'create_time'
      },
      searchReplayForm: {
        page: 1,
        woId: null,
        limit: 10,
        searchKey: null
      },
      formData: {
        id: null,
        woId: null,
        content: null
      },
      rules: {
        content: [{
          required: true,
          message: '回复内容不能为空',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      queryReplayLoading: true,
      tableData: [],
      replayTableData: [],
      totalCount: 0,
      replayTotalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '80px',
      emptyFormData: {},
      isMe: localStorage.getItem('userId'),
      workOrderDetail: {
        content: null,
        createUserNickname: null,
        userPhone: null
      }
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    detailAndReplay: function detailAndReplay(row) {
      var _this = this;
      this.formData.woId = row.id;
      this.searchReplayForm.woId = row.id;
      this.workOrderDetail = row;
      if (!row.readState) {
        this.$api[this.pageConfig.activeWorkOrderFunName](row.id).then(function (res) {
          if (res.data) {
            _this.$message.success('已读');
            _this.query();
          } else {
            _this.$message.error('已读失败');
          }
        });
      }
      this.queryReplay();
      this.editDialogTitle = this.pageConfig.editPageName + '详情与回复';
      this.editDialogShow = true;
    },
    query: function query() {
      var _this2 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.totalCount = res.count;
        _this2.queryLoading = false;
      });
    },
    queryReplay: function queryReplay() {
      var _this3 = this;
      this.queryReplayLoading = true;
      this.$api[this.pageConfig.queryReplayFunName](this.searchReplayForm).then(function (res) {
        _this3.replayTableData = res.data || [];
        for (var i in res.data) {
          if (JSON.parse(res.data[i].imgs) && res.data[i].imgs !== '[]') {
            _this3.replayTableData[i].imgs = JSON.parse(res.data[i].imgs);
          } else {
            _this3.replayTableData[i].imgs = null;
          }
        }
        _this3.replayTotalCount = res.count;
        _this3.queryReplayLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this4 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          _this4.$api[_this4.pageConfig.addReplayFunName](_this4.saveFormDataProcess(_this4.formData)).then(function (res) {
            if (res.data) {
              _this4.$message.success('回复成功');
              _this4.initFormData(_this4.emptyFormData);
              _this4.editDialogShow = false;
              _this4.query();
            } else {
              _this4.$message.error('回复失败');
            }
          });
        }
      });
    },
    previewFile: function previewFile(path) {
      window.open(path);
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    replayPageChange: function replayPageChange(page) {
      this.searchReplayForm.page = page;
      this.queryReplay();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    replaySizeChange: function replaySizeChange(limit) {
      this.searchReplayForm.page = 1;
      this.searchReplayForm.limit = limit;
      this.queryReplay();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};