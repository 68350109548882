export default (function (_ref) {
  var request = _ref.request;
  return {
    payUserMyAccount: function payUserMyAccount() {
      return request({
        url: '/pay/user/my-account',
        method: 'get'
      });
    }
  };
});