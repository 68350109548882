export default (function (_ref) {
  var request = _ref.request;
  return {
    asNoticeAdd: function asNoticeAdd(data) {
      return request({
        url: '/asNotice',
        method: 'post',
        data: data
      });
    },
    asNoticeModify: function asNoticeModify(data) {
      return request({
        url: "/asNotice/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    asNoticeDel: function asNoticeDel(id) {
      return request({
        url: "/asNotice/".concat(id),
        method: 'delete'
      });
    },
    asNoticeQuery: function asNoticeQuery(data) {
      return request({
        url: '/asNotice',
        method: 'get',
        params: data
      });
    },
    asNoticeDetail: function asNoticeDetail(id) {
      return request({
        url: "/asNotice/".concat(id),
        method: 'get'
      });
    },
    pushNotice: function pushNotice(id) {
      return request({
        url: '/asNotice/publish',
        method: 'get',
        params: {
          id: id
        }
      });
    }
  };
});