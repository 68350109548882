import dayjs from 'dayjs';
export default (function (_ref) {
  var request = _ref.request;
  return {
    piAdd: function piAdd(data) {
      return request({
        url: '/sePatrolInspection',
        method: 'post',
        data: data
      });
    },
    piModify: function piModify(data) {
      return request({
        url: "/sePatrolInspection/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    piDel: function piDel(id) {
      return request({
        url: "/sePatrolInspection/".concat(id),
        method: 'delete'
      });
    },
    piQuery: function piQuery(data) {
      return request({
        url: '/sePatrolInspection',
        method: 'get',
        params: data
      });
    },
    piDetail: function piDetail(id) {
      return request({
        url: "/sePatrolInspection/".concat(id),
        method: 'get'
      });
    },
    piAudit: function piAudit(data) {
      return request({
        url: '/sePatrolInspection/audit',
        method: 'PUT',
        data: data
      });
    }
  };
});