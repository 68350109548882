export default (function (_ref) {
  var request = _ref.request;
  return {
    payOrderQuery: function payOrderQuery(data) {
      return request({
        url: '/payOrder/queryJoinUser',
        method: 'get',
        params: data
      });
    },
    payOrderDetail: function payOrderDetail(id) {
      return request({
        url: "/payOrder/".concat(id),
        method: 'get'
      });
    },
    payOrderAgainSendNotice: function payOrderAgainSendNotice(id) {
      return request({
        url: "/payOrder/".concat(id, "/againSendNotice"),
        method: 'post'
      });
    }
  };
});