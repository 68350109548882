export default (function (_ref) {
  var request = _ref.request;
  return {
    asPostAdd: function asPostAdd(data) {
      return request({
        url: '/asPost',
        method: 'post',
        data: data
      });
    },
    asPostModify: function asPostModify(data) {
      return request({
        url: "/asPost/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    asPostDel: function asPostDel(id) {
      return request({
        url: "/asPost/".concat(id),
        method: 'delete'
      });
    },
    asPostQuery: function asPostQuery(data) {
      return request({
        url: '/asPost',
        method: 'get',
        params: data
      });
    },
    asPostDetail: function asPostDetail(id) {
      return request({
        url: "/asPost/".concat(id),
        method: 'get'
      });
    },
    asPostByAssociationId: function asPostByAssociationId(associationId) {
      return request({
        url: '/asPost/list/association',
        method: 'get',
        params: {
          associationId: associationId
        }
      });
    }
  };
});