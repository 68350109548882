export default (function (_ref) {
  var request = _ref.request;
  return {
    grReservationConfigAdd: function grReservationConfigAdd(data) {
      return request({
        url: '/grReservationConfig',
        method: 'post',
        data: data
      });
    },
    grReservationConfigModify: function grReservationConfigModify(data) {
      return request({
        url: "/grReservationConfig/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    grReservationConfigDel: function grReservationConfigDel(id) {
      return request({
        url: "/grReservationConfig/".concat(id),
        method: 'delete'
      });
    },
    grReservationConfigQuery: function grReservationConfigQuery(data) {
      return request({
        url: '/grReservationConfig',
        method: 'get',
        params: data
      });
    },
    grReservationConfigDetail: function grReservationConfigDetail(id) {
      return request({
        url: "/grReservationConfig/".concat(id),
        method: 'get'
      });
    },
    grReservationConfigByGymId: function grReservationConfigByGymId(id) {
      return request({
        url: "/grReservationConfig/".concat(id),
        method: 'get'
      });
    }
  };
});