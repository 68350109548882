import "core-js/modules/es.object.to-string.js";
export default (function (_ref) {
  var request = _ref.request;
  return {
    aliOssSign: function aliOssSign(userId) {
      if (process.env.VUE_APP_UPLOAD_TYPE === 'server') {
        return new Promise(function (resolve, reject) {
          resolve({
            data: '上传到服务器'
          });
        });
      }
      return request({
        url: "/oss/aliyun/sign/".concat(userId),
        method: 'post'
      });
    }
  };
});