import _createForOfIteratorHelper from "D:/projectSpace/snht/snhtweb/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import * as echarts from 'echarts';
export default {
  name: 'statistic_analysis_page',
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      datePickerValue: '',
      charts: {
        repairRate: null,
        repairDuration: null
      }
    };
  },
  mounted: function mounted() {
    this.initChart();
  },
  methods: {
    changeDatePicker: function changeDatePicker(a) {
      var _this = this;
      var param = {
        startTime: this.dayjs(a[0]).format('YYYY-MM-DD HH:mm:ss'),
        endTime: this.dayjs(a[1]).format('YYYY-MM-DD HH:mm:ss')
      };
      this.$api.repairReportRepairRate(param).then(function (res) {
        if (res.data) {
          _this.renderChartRepairRate(res.data);
        }
      });
      this.$api.repairReportRepairDuration(param).then(function (res) {
        if (res.data) {
          _this.renderChartRepairDuration(res.data);
        }
      });
    },
    initChart: function initChart() {
      this.charts.repairRate = echarts.init(document.getElementById('repairRate'));
      this.charts.repairDuration = echarts.init(document.getElementById('repairDuration'));
    },
    renderChartRepairRate: function renderChartRepairRate(data) {
      var nameArr = [];
      var valueArr = [];
      var _iterator = _createForOfIteratorHelper(data),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var d = _step.value;
          nameArr.push(d.name);
          valueArr.push(d.value);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      this.charts.repairRate && this.charts.repairRate.setOption({
        title: {
          text: '区域维修率表'
        },
        xAxis: {
          type: 'category',
          data: nameArr
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: valueArr,
          type: 'bar'
        }]
      });
    },
    renderChartRepairDuration: function renderChartRepairDuration(data) {
      var nameArr = [];
      var valueArr = [];
      var _iterator2 = _createForOfIteratorHelper(data),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var d = _step2.value;
          nameArr.push("".concat(d.name, " \u5C0F\u65F6"));
          valueArr.push(d.value);
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      this.charts.repairDuration && this.charts.repairDuration.setOption({
        title: {
          text: '维修时长分布表'
        },
        xAxis: {
          type: 'category',
          data: nameArr
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: valueArr,
          type: 'bar'
        }]
      });
    }
  }
};