import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import dayjs from 'dayjs';
export default (function (_ref) {
  var request = _ref.request;
  return {
    seAdd: function seAdd(data) {
      return request({
        url: '/seSportsEquipment',
        method: 'post',
        data: data
      });
    },
    seModify: function seModify(data) {
      return request({
        url: "/seSportsEquipment/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    seDel: function seDel(id) {
      return request({
        url: "/seSportsEquipment/".concat(id),
        method: 'delete'
      });
    },
    seQuery: function seQuery(data) {
      return request({
        url: '/seSportsEquipment',
        method: 'get',
        params: data
      });
    },
    seDetail: function seDetail(id) {
      return request({
        url: "/seSportsEquipment/".concat(id),
        method: 'get'
      });
    },
    seUpload: function seUpload(file) {
      var formData = new FormData();
      formData.append('file', file);
      return request({
        url: '/seSportsEquipment/import-excel',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data; boundary = ' + new Date().getTime(),
          Authorization: localStorage.getItem('token')
        },
        responseType: 'blob'
      }).then(function (res) {
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(res);
        downloadElement.href = href;
        var format = dayjs().format('YYYYMMDDHHmmss');
        downloadElement.download = "\u5668\u6750\u5BFC\u5165\u5F02\u5E38\u6570\u636E_".concat(format, ".xls");
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    exportQrCode: function exportQrCode() {
      return request({
        url: '/seSportsEquipment/export-qr-code',
        method: 'GET',
        params: {
          width: 1200,
          height: 1200
        },
        headers: {
          'Content-Type': 'multipart/form-data; boundary = ' + new Date().getTime(),
          Authorization: localStorage.getItem('token')
        },
        responseType: 'blob'
      }).then(function (res) {
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(res);
        downloadElement.href = href;
        var format = dayjs().format('YYYYMMDDHHmmss');
        downloadElement.download = "\u5668\u6750\u5BFC\u51FA\u4E8C\u7EF4\u7801_".concat(format, ".zip");
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    confirmLonlat: function confirmLonlat(id, confirm) {
      return request({
        url: '/seSportsEquipment/confirm-lonlat',
        method: 'GET',
        params: {
          id: id,
          confirm: confirm
        }
      });
    }
  };
});