import "core-js/modules/es.array.map.js";
/**
 * @description 创建菜单
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */
export function elMenuItem(h, menu) {
  var icon = null;
  if (menu.icon) {
    icon = h("i", {
      "class": "".concat(menu.icon)
    });
  } else if (menu.iconSvg) {
    icon = h("d2-icon-svg", {
      "attrs": {
        "name": menu.iconSvg
      }
    });
  } else {
    icon = h("i", {
      "class": "fa fa-file-o"
    });
  }
  return h("el-menu-item", {
    "key": menu.path,
    "attrs": {
      "index": menu.path
    }
  }, [icon, h("span", {
    "slot": "title"
  }, [menu.title || '未命名菜单'])]);
}

/**
 * @description 创建子菜单
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */
export function elSubmenu(h, menu) {
  var _this = this;
  var icon = null;
  if (menu.icon) {
    icon = h("i", {
      "slot": "title",
      "class": "".concat(menu.icon)
    });
  } else if (menu.iconSvg) {
    icon = h("d2-icon-svg", {
      "slot": "title",
      "attrs": {
        "name": menu.iconSvg
      }
    });
  } else {
    icon = h("i", {
      "slot": "title",
      "class": "fa fa-folder-o"
    });
  }
  return h("el-submenu", {
    "key": menu.path,
    "attrs": {
      "index": menu.path
    }
  }, [icon, h("span", {
    "slot": "title"
  }, [menu.title || '未命名菜单']), menu.children.map(function (child) {
    return createMenu.call(_this, h, child);
  })]);
}

/**
 * @description 创建头部子菜单
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */
export function elHeaderSubmenu(h, menu) {
  var _this2 = this;
  var icon = null;
  if (menu.icon) {
    icon = h("i", {
      "slot": "title",
      "class": "".concat(menu.icon)
    });
  } else if (menu.iconSvg) {
    icon = h("d2-icon-svg", {
      "slot": "title",
      "attrs": {
        "name": menu.iconSvg
      }
    });
  } else {
    icon = h("i", {
      "slot": "title",
      "class": "fa fa-folder-o"
    });
  }
  return h("el-submenu", {
    "attrs": {
      "popper-class": "header-submenu",
      "index": menu.path
    },
    "key": menu.path
  }, [icon, h("span", {
    "slot": "title"
  }, [menu.title || '未命名菜单']), menu.children.map(function (child) {
    return createMenu.call(_this2, h, child);
  })]);
}

/**
 * @description 在组件中调用此方法渲染菜单项目
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */
export function createMenu(h, menu) {
  if (typeof menu.children === 'undefined') {
    return elMenuItem.call(this, h, menu);
  }
  return elSubmenu.call(this, h, menu);
}

/**
 * @description 在组件中调用此方法渲染菜头部单项目
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */
export function createHeaderMenu(h, menu) {
  if (typeof menu.children === 'undefined') {
    return elMenuItem.call(this, h, menu);
  }
  return elHeaderSubmenu.call(this, h, menu);
}