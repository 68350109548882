import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import * as echarts from 'echarts';
export default {
  name: 'data_analysis_page',
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      timeRange: [],
      queryParam: {},
      myGymList: [],
      sportsEventsList: [],
      chart: {
        reservationRate: {},
        weekDistribution: {}
      }
    };
  },
  mounted: function mounted() {
    this.loadMyGymList();
    // 初始化预约率图表
    this.initChart1();
    // 初始化星期分布表
    this.initChart2();
  },
  methods: {
    clickQuery: function clickQuery() {
      // 重新加载：每日预约率
      console.log('点击查询', this.queryParam);
      this.loadDailyReservationRate();
      // 重新加载：星期分布
      this.loadWeekDistribution();
    },
    loadWeekDistribution: function loadWeekDistribution() {
      var _this = this;
      this.$api.ticketWeekDistribution(this.queryParam).then(function (res) {
        var data = res.data;
        if (data) {
          var option = _this.chart.weekDistribution.getOption();
          var week = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'];
          option.series[0].data = data.map(function (d, i) {
            return {
              name: week[i],
              value: d
            };
          });
          _this.chart.weekDistribution.setOption(option);
        }
      });
    },
    loadDailyReservationRate: function loadDailyReservationRate() {
      var _this2 = this;
      this.$api.capacityDailyReservationRate(this.queryParam).then(function (res) {
        var data = res.data;
        if (data) {
          _this2.chart.reservationRate.setOption({
            xAxis: {
              data: data.map(function (d) {
                return d.name;
              })
            },
            series: [{
              data: data.map(function (d) {
                return d.value;
              }),
              type: 'line'
            }]
          });
        }
      });
    },
    loadMyGymList: function loadMyGymList() {
      var _this3 = this;
      this.$api.myGymList().then(function (res) {
        if (res.data) {
          _this3.myGymList = res.data;
        }
      });
    },
    changeGym: function changeGym(gymId) {
      var _this4 = this;
      this.$set(this, 'sportsEventsList', []);
      this.$api.grGymDetail(gymId).then(function (res) {
        var data = res.data;
        if (data && data.sportsEvents) {
          var sportsEvents = data.sportsEvents;
          var split = sportsEvents.split(',');
          var filter = _this4.dictionaryAll.filter(function (d) {
            return split.includes(d.code);
          });
          _this4.$set(_this4, 'sportsEventsList', filter);
        }
      });
    },
    changeDatePicker: function changeDatePicker(a) {
      this.$set(this.queryParam, 'startTime', this.dayjs(a[0]).format('YYYY-MM-DD'));
      this.$set(this.queryParam, 'endTime', this.dayjs(a[1]).format('YYYY-MM-DD'));
    },
    initChart1: function initChart1() {
      var chart1 = echarts.init(document.getElementById('reservationRate'));
      var option1 = {
        title: {
          text: '预约率'
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: [],
          type: 'line'
        }]
      };
      chart1 && chart1.setOption(option1);
      this.chart.reservationRate = chart1;
    },
    initChart2: function initChart2() {
      var myChart = echarts.init(document.getElementById('weekDistribution'));
      myChart.setOption({
        title: {
          text: '星期分布'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{c} ({d}%)'
        },
        series: [{
          type: 'pie',
          radius: [25, 125],
          center: ['50%', '50%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 4
          },
          data: []
        }]
      });
      this.chart.weekDistribution = myChart;
    }
  }
};