export default (function (_ref) {
  var request = _ref.request;
  return {
    gmGameAdd: function gmGameAdd(data) {
      return request({
        url: '/gmGame',
        method: 'post',
        data: data
      });
    },
    gmGameModify: function gmGameModify(data) {
      return request({
        url: "/gmGame/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    gmGameDel: function gmGameDel(id) {
      return request({
        url: "/gmGame/".concat(id),
        method: 'delete'
      });
    },
    gmGameQuery: function gmGameQuery(data) {
      return request({
        url: '/gmGame',
        method: 'get',
        params: data
      });
    },
    gmGameDetail: function gmGameDetail(id) {
      return request({
        url: "/gmGame/".concat(id),
        method: 'get'
      });
    },
    gameListForRecordResult: function gameListForRecordResult() {
      return request({
        url: '/gmGame/select-list/record-result',
        method: 'get'
      });
    }
  };
});