export default (function (_ref) {
  var request = _ref.request;
  return {
    gmGameResultAdd: function gmGameResultAdd(data) {
      return request({
        url: '/gmGameResult',
        method: 'post',
        data: data
      });
    },
    gmGameResultModify: function gmGameResultModify(data) {
      return request({
        url: "/gmGameResult/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    gmGameResultDel: function gmGameResultDel(id) {
      return request({
        url: "/gmGameResult/".concat(id),
        method: 'delete'
      });
    },
    gmGameResultQuery: function gmGameResultQuery(data) {
      return request({
        url: '/gmGameResult',
        method: 'get',
        params: data
      });
    },
    gmGameResultDetail: function gmGameResultDetail(id) {
      return request({
        url: "/gmGameResult/".concat(id),
        method: 'get'
      });
    }
  };
});