import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.concat.js";
export default (function (_ref) {
  var request = _ref.request;
  return {
    userAdd: function userAdd(data) {
      return request({
        url: '/user',
        method: 'post',
        data: data
      });
    },
    userModify: function userModify(data) {
      return request({
        url: "/user/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    userDel: function userDel(id) {
      return request({
        url: "/user/".concat(id),
        method: 'delete'
      });
    },
    userQuery: function userQuery(data) {
      return request({
        url: '/user',
        method: 'get',
        params: data
      });
    },
    userDetail: function userDetail(id) {
      return request({
        url: "/user/".concat(id),
        method: 'get'
      });
    },
    userDetailHasRole: function userDetailHasRole(id) {
      return request({
        url: "/user/".concat(id, "/hasRule"),
        method: 'get'
      });
    },
    userDisable: function userDisable(id) {
      return request({
        url: "/user/".concat(id, "/disable"),
        method: 'put'
      });
    },
    userUnDisable: function userUnDisable(id) {
      return request({
        url: "/user/".concat(id, "/unDisable"),
        method: 'put'
      });
    },
    userUnLock: function userUnLock(id) {
      return request({
        url: "/user/".concat(id, "/unLock"),
        method: 'put'
      });
    },
    userResetPassword: function userResetPassword(id) {
      return request({
        url: "/user/".concat(id, "/resetPassword"),
        method: 'put'
      });
    },
    userChangeCurrentPassword: function userChangeCurrentPassword(data) {
      return request({
        url: "/user/changeCurrentPassword",
        method: 'put',
        data: data
      });
    },
    userSignRevoke: function userSignRevoke(id) {
      return request({
        url: "/user/sign/revoke",
        method: 'delete'
      });
    } /////////////////////////////////////////////////////////
    ,
    userClearAuth: function userClearAuth(id) {
      return request({
        url: "/user/".concat(id, "/clearAuthAndDel"),
        method: 'delete'
      });
    },
    userQueryDoNotHaveAdmin: function userQueryDoNotHaveAdmin(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      paramData.category = paramData.category ? paramData.category : 'not_admin';
      return request({
        url: '/user/query',
        method: 'get',
        params: paramData
      });
    },
    userQueryJoinIntroduce: function userQueryJoinIntroduce(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      paramData.category = paramData.category ? paramData.category : 'not_admin';
      return request({
        url: '/user/queryJoinIntroduce',
        method: 'get',
        params: paramData
      });
    },
    userAddBalance: function userAddBalance(id, balance, content) {
      return request({
        url: "/user/".concat(id, "/addBalance?balance=").concat(balance, "&content=").concat(content),
        method: 'post'
      });
    },
    userSubBalance: function userSubBalance(id, balance, content) {
      return request({
        url: "/user/".concat(id, "/subBalance?balance=").concat(balance, "&content=").concat(content),
        method: 'post'
      });
    },
    wxWebLogin: function wxWebLogin(category, code) {
      return request({
        url: "/user/login/web/".concat(category, "/").concat(code),
        method: 'post'
      });
    }
  };
});