export default (function (_ref) {
  var request = _ref.request;
  return {
    sePathAdd: function sePathAdd(data) {
      return request({
        url: '/sePath',
        method: 'post',
        data: data
      });
    },
    sePathModify: function sePathModify(data) {
      return request({
        url: "/sePath/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    sePathDel: function sePathDel(id) {
      return request({
        url: "/sePath/".concat(id),
        method: 'delete'
      });
    },
    sePathQuery: function sePathQuery(data) {
      return request({
        url: '/sePath',
        method: 'get',
        params: data
      });
    },
    sePathDetail: function sePathDetail(id) {
      return request({
        url: "/sePath/".concat(id),
        method: 'get'
      });
    }
  };
});