import dayjs from 'dayjs';
export default (function (_ref) {
  var request = _ref.request;
  return {
    seCitizenRepairAdd: function seCitizenRepairAdd(data) {
      return request({
        url: '/seCitizenRepair',
        method: 'post',
        data: data
      });
    },
    seCitizenRepairModify: function seCitizenRepairModify(data) {
      return request({
        url: "/seCitizenRepair/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    seCitizenRepairDel: function seCitizenRepairDel(id) {
      return request({
        url: "/seCitizenRepair/".concat(id),
        method: 'delete'
      });
    },
    seCitizenRepairQuery: function seCitizenRepairQuery(data) {
      return request({
        url: '/seCitizenRepair',
        method: 'get',
        params: data
      });
    },
    seCitizenRepairDetail: function seCitizenRepairDetail(id) {
      return request({
        url: "/seCitizenRepair/".concat(id),
        method: 'get'
      });
    }
  };
});